import React, { useState, useEffect } from "react";
import OfferHeader from "../basic/OfferHeader";
import OfferContent from "../basic/OfferContent";
import OfferPost from "../basic/OfferPost";
import { v4tov3 } from "../../utils/v4tov3";
import { useQuery } from "@apollo/client";

const Posts = (props) => {
  const { query } = props;
  const { data: v4postData } = useQuery(query);

  const [postData, setPostData] = useState();

  useEffect(() => {
    if (v4postData) {
      setPostData(v4tov3({ v4res: v4postData, key: "kategories" }));
    }
  }, [v4postData]);

  // compares the name of two images to sort the images by name
  const compare = (imgA, imgB) => {
    if (imgA.name < imgB.name) {
      return -1;
    }
    if (imgA.name > imgB.name) {
      return 1;
    }
    return 0;
  };

  return (
    <div>
      {postData ? (
        <>
          <OfferHeader title={props.category}></OfferHeader>
          <OfferContent contents={postData.kategories[0].posts}></OfferContent>
          {postData.kategories[0].posts.map((post, i) => (
            <OfferPost
              key={i}
              title={post.titel}
              text1={post.text1}
              publication={{
                text: post.PublikationText,
                link: post.PublikationLink,
              }}
              image={post.bild.sort(compare)}
              text2={post.text2}
              slideshow={post.slideshow}
              offerPath={props.offerPath}
            />
          ))}
        </>
      ) : null}
    </div>
  );
};

export default Posts;
