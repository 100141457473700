import React, { useEffect } from 'react';
import ContactSection from '../sections/ContactSection';
import NavBar from '../basic/Navbar';
import Footer from '../basic/Footer';

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className='pageContainer'>
      <NavBar sticky={true} />
      <ContactSection />
      <Footer />
    </div>
  );
};

export default ContactPage;
