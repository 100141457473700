import gql from "graphql-tag";

const QUERY_SOZIALFORSCHUNG = gql`
  query {
    kategories(
      filters: { name: { eq: "Sozialforschung" } }
      pagination: { limit: 100 }
    ) {
      data {
        attributes {
          posts(sort: ["position:DESC"]) {
            data {
              attributes {
                titel
                text1
                bild(pagination: { limit: 100 }) {
                  data {
                    attributes {
                      name
                      formats
                      caption
                    }
                  }
                }
                PublikationText
                text2
                slideshow
              }
            }
          }
        }
      }
    }
  }
`;

export default QUERY_SOZIALFORSCHUNG;
