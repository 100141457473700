import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ListOfWork from '../basic/ListOfWork';

import QUERY_PUB_GUTACHTEN from '../../queries/query_pub_gutachten';
import QUERY_VORTRAEGE from '../../queries/query_vortraege';

const ListOfWorkSection = () => {
  return (
    <div id='listOfWorkSection'>
      <Container>
        <Row>
          <Col sm={1} />
          <Col sm={10}>
            <hr />
          </Col>
          <Col sm={1} />
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={10}>
            <ListOfWork
              title='Publikationen / Gutachten'
              query={QUERY_PUB_GUTACHTEN}
            />
          </Col>
          <Col sm={1} />
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={10}>
            <hr />
          </Col>
          <Col sm={1} />
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={10}>
            <ListOfWork title='Vorträge' query={QUERY_VORTRAEGE} />
          </Col>
          <Col sm={1} />
        </Row>
        <Row>
          <Col sm={1} />
          <Col sm={10}>
            <hr />
          </Col>
          <Col sm={1} />
        </Row>
      </Container>
    </div>
  );
};

export default ListOfWorkSection;
