import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import SingleImg from "../basic/SingleImg";
import ImgSlideShow from "../basic/ImgSlideShow";
import ImgGallery from "../basic/ImgGallery";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { v4tov3 } from "../../utils/v4tov3";
import ReactMarkdown from "react-markdown";

import QUERY_POST_PUB from "../../queries/query_post_pub";
import QUERY_POST_VORTRAEGE from "../../queries/query_post_vortraege";
import WorkItem from "./WorkItem";

const Post = (props) => {
  const { title, offerPath } = props;
  const [scrollPos, setScrollPos] = useState(0);

  const { data: v4publikationData } = useQuery(QUERY_POST_PUB, {
    variables: { postTitle: title },
  });

  const { data: v4vortraegeData } = useQuery(QUERY_POST_VORTRAEGE, {
    variables: { postTitle: title },
  });

  const [publikationData, setPublikationData] = useState();
  const [vortraegeData, setVortraegeData] = useState();

  useEffect(() => {
    if (v4publikationData) {
      setPublikationData(
        v4tov3({ v4res: v4publikationData, key: "publikations" })
      );
    }
  }, [v4publikationData]);

  useEffect(() => {
    if (v4vortraegeData) {
      setVortraegeData(v4tov3({ v4res: v4vortraegeData, key: "publikations" }));
    }
  }, [v4vortraegeData]);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPos(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Container
      id={props.title
        .replace(/ü|Ü/g, "ue")
        .replace(/ä|Ä/g, "ae")
        .replace(/ö|Ö/g, "oe")
        .replace(/ß/g, "ss")}
      className="offerPost"
    >
      <Row>
        <Col sm={2} />
        <Col sm={8}>
          <div className="seperator"></div>
        </Col>
        <Col sm={2} />
      </Row>
      <Row>
        <Col sm={3} />
        <Col sm={6}>
          <h1>{props.title}</h1>
        </Col>
        <Col sm={3} />
      </Row>
      <Row>
        <Col sm={3} />
        <Col sm={6}>
          <div className="postText">
            <ReactMarkdown className="postText" children={props.text1} />
          </div>
        </Col>
        <Col sm={3} />
      </Row>
      {props.publication.text !== null || props.publication.link !== null ? (
        <Row>
          <Col sm={3} />
          <Col sm={6}>
            <WorkItem
              text={props.publication.text}
              link={props.publication.link}
            />
          </Col>
          <Col sm={3} />
        </Row>
      ) : null}
      {(() => {
        switch (props.image.length) {
          case 0:
            return null;
          case 1:
            return <SingleImg image={props.image[0]}></SingleImg>;
          default:
            if (props.slideshow) {
              return <ImgSlideShow images={props.image}></ImgSlideShow>;
            } else {
              return <ImgGallery images={props.image}></ImgGallery>;
            }
        }
      })()}
      {props.text2 !== null ? (
        <Row>
          <Col sm={3} />
          <Col sm={6}>
            <div className="postText">
              <ReactMarkdown children={props.text2} />
            </div>
          </Col>
          <Col sm={3} />
        </Row>
      ) : null}
      <div className="pubLinkContainer">
        {publikationData && publikationData.publikations.length !== 0 ? (
          <Row>
            <Col sm={3} />
            <Col sm={6}>
              <Link
                className="pubLink"
                to={"/publikationen-und-gutachten/".concat(title)}
                state={{
                  scrollPos: scrollPos,
                  offerPath,
                }}
              >
                Publikationen und Gutachten
              </Link>
            </Col>
            <Col sm={3} />
          </Row>
        ) : null}
        {vortraegeData && vortraegeData.publikations.length !== 0 ? (
          <Row>
            <Col sm={3} />
            <Col sm={6}>
              <Link
                className="pubLink"
                to={"/vortraege/".concat(title)}
                state={{
                  scrollPos: scrollPos,
                  offerPath,
                }}
              >
                Vorträge
              </Link>
            </Col>
            <Col sm={3} />
          </Row>
        ) : null}
      </div>
    </Container>
  );
};

export default Post;
