import gql from "graphql-tag";

const QUERY_VORTRAEGE = gql`
  query ($postTitle: String!) {
    publikations(
      filters: { art: { eq: "Vortrag" }, post: { titel: { eq: $postTitle } } }
      sort: "datum:DESC"
      pagination: { limit: 1000 }
    ) {
      data {
        attributes {
          text
          link
        }
      }
    }
  }
`;

export default QUERY_VORTRAEGE;
