import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import SozialforschungPage from "./components/pages/SozialforschungPage";
import BeratungPage from "./components/pages/BeratungPage";
import ImpressumPage from "./components/pages/ImpressumPage";
import DatenschutzPage from "./components/pages/DatenschutzPage";
import Publications from "./components/pages/Publications";
import AboutPage from "./components/pages/AboutPage";
import ContactPage from "./components/pages/ContactPage";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import CurrentPage from "./components/pages/CurrentPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route
          path="/offer"
          exact
          element={<LandingPage scrollTo={"offer"} />}
        />
        <Route path="/about" exact element={<AboutPage scrollTo={"about"} />} />
        <Route path="/contact" exact element={<ContactPage />} />
        <Route path="/current" exact element={<CurrentPage />} />
        <Route
          path="/sozialforschung"
          exact
          element={<SozialforschungPage />}
        />
        <Route path="/beratung" exact element={<BeratungPage />} />
        <Route path="/impressum" exact element={<ImpressumPage />} />
        <Route path="/datenschutz" exact element={<DatenschutzPage />} />
        <Route
          path="/publikationen-und-gutachten/:postTitle"
          exact
          element={<Publications title="Publikationen und Gutachten" />}
        />
        <Route
          path="/vortraege/:postTitle"
          exact
          element={<Publications title="Vorträge" />}
        />
      </Routes>
    </Router>
  );
};

export default App;
