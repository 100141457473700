import React, { useRef, useEffect } from "react";
import LandingSection from "../sections/LandingSection";
import NavBar from "../basic/Navbar";
import OfferSection from "../sections/OfferSection";
import Footer from "../basic/Footer";

const scrollToRef = (ref) => {
  window.scrollTo(0, ref.current.offsetTop - 100);
};

const LandingPage = ({ scrollTo }) => {
  const offerRef = useRef();

  useEffect(() => {
    switch (scrollTo) {
      case "offer":
        scrollToRef(offerRef);
        break;
      default:
        window.scrollTo(0, 0);
    }
  }, [scrollTo]);

  return (
    <div className="pageContainer">
      <LandingSection />
      <NavBar sticky={false} />
      <OfferSection refProp={offerRef} />
      <Footer />
    </div>
  );
};

export default LandingPage;
