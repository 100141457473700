import React from 'react';

const WorkItem = props => {
  return (
    <div id='workItem' ref={props.refProp}>
      <p>{props.text}</p>
      {props.link ? (
        <a href={props.link} target='_blank' rel='noopener noreferrer'>
          {props.link}
        </a>
      ) : null}
    </div>
  );
};

export default WorkItem;
