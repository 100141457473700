import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { v4tov3 } from "../../utils/v4tov3";
import OfferPost from "../basic/OfferPost";

import QUERY_CURRENT from "../../queries/query_current";

const CurrentSection = () => {
  const { data: v4currentData } = useQuery(QUERY_CURRENT);
  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    if (v4currentData) {
      setCurrentData(v4tov3({ v4res: v4currentData, key: "kategories" }));
    }
  }, [v4currentData]);

  return (
    <div id="currentSection">
      <h1 className="currentHeader">Aktuelle Projekte</h1>
      {currentData
        ? currentData.kategories[0].posts.map((post, i) => (
            <OfferPost
              key={i}
              title={post.titel}
              text1={post.text1}
              publication={{
                text: post.PublikationText,
                link: post.PublikationLink,
              }}
              image={post.bild}
              text2={post.text2}
              slideshow={post.Slideshow}
            ></OfferPost>
          ))
        : null}
    </div>
  );
};

export default CurrentSection;
