import React from 'react';

const ContactForm = () => {
  return (
    <div id='contactForm'>
      <h1 className='contactHeader'>
        Bitte kontaktieren Sie mich per E-Mail, Telefon oder Post:
      </h1>
      <div className='contactDataContainer'>
        <div className='contactData'>
          <p>Professor Lakemann GmbH</p>
          <p>Sozialforschung und Beratung</p>
          <p>Mittelstraße 17</p>
          <p>07745 Jena</p>
          <br />
          <p>
            <i>Telefon: </i> +49 (0) 172 9795543
          </p>
          <p>
            <i>E-Mail: </i>
            info@lakemann.com
          </p>
          <p>
            <i>Website: </i>
            <a href='https://www.lakemann.com'>www.lakemann.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;

// import React, { Component } from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import axios from 'axios';

// export class ContactForm extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       firstName: '',
//       lastName: '',
//       subject: '',
//       email: '',
//       message: '',
//     };
//   }

//   onChange = e => {
//     this.setState({
//       ...this.state,
//       [e.target.name]: [e.target.value],
//     });
//   };

//   buildHTML = message => {
//     message = message[0].split('\n');
//     let html = '';
//     for (let m of message) {
//       html = html.concat(`<p>${m.replace(/<\/?[^>]+(>|$)/g, '')}</p>`);
//     }
//     return html;
//   };

//   onSubmit = e => {
//     e.preventDefault();
//     const { firstName, lastName, subject, email, message } = this.state;
//     const htmlMessage = this.buildHTML(message);
//     const response = axios.post(`${process.env.REACT_APP_DATABASE_URL}/email`, {
//       to: process.env.REACT_APP_CONTACT_FORM_EMAIL,
//       replyTo: email,
//       subject: subject[0],
//       html: `
//         ${htmlMessage}
//         <br />
//         <br />
//         <hr />
//         <br />
//         <p>Diese Nachricht wurde über das Kontaktformular der Website
//         <a href='https://www.lakemann.com/'>lakemann.com</a>
//         verschickt.<br />
//         Der Absender ist:</p>
//         <p>Vorname: ${firstName}</p>
//         <p>Nachname: ${lastName}</p>
//         <p>Email: ${email}</p>
//         `,
//     });
//     response.then(result => {
//       if (result.status === 200) {
//         axios.post(`${process.env.REACT_APP_DATABASE_URL}/email`, {
//           to: email[0],
//           subject: 'Bestätigung: Nachricht an Lakemann GmbH',
//           html: `
//             <p>Sehr geehrte*r ${firstName} ${lastName},</p>
//             <p>die folgende Nachricht wurde erfolgreich an die Professor Lakemann GmbH übermittelt.</p>
//             <i>${htmlMessage}</i>
//             <br/>
//             <hr/>
//             <br />
//             <p>Diese Nachricht wurde maschinell erstellt</p>
//             `,
//         });
//         this.setState({
//           ...this.state,
//           sendingStatus: 'Nachricht wurde erfolgreich versendet.',
//           firstName: '',
//           lastName: '',
//           subject: '',
//           email: '',
//           message: '',
//         });
//       } else {
//         this.setState({
//           ...this.state,
//           sendingStatus:
//             'Beim senden der Nachricht ist ein Fehler aufgetreten.',
//           firstName: '',
//           lastName: '',
//           subject: '',
//           email: '',
//           message: '',
//         });
//       }
//     });
//   };

//   render() {
//     const {
//       firstName,
//       lastName,
//       subject,
//       email,
//       message,
//       sendingStatus,
//     } = this.state;
//     return (
//       <Container id='contactForm'>
//         <form onSubmit={this.onSubmit}>
//           <Row>
//             <Col sm={2}></Col>
//             <Col sm={4}>
//               <input
//                 name='firstName'
//                 type='text'
//                 value={firstName}
//                 onChange={this.onChange}
//                 placeholder={'Vorname'}
//                 required
//               />
//             </Col>
//             <Col sm={4}>
//               <input
//                 name='lastName'
//                 type='text'
//                 value={lastName}
//                 onChange={this.onChange}
//                 placeholder={'Nachname'}
//                 required
//               />
//             </Col>
//             <Col sm={2}></Col>
//           </Row>
//           <Row>
//             <Col sm={2}></Col>
//             <Col sm={4}>
//               <input
//                 name='subject'
//                 type='text'
//                 value={subject}
//                 onChange={this.onChange}
//                 placeholder={'Betreff'}
//                 required
//               />
//             </Col>
//             <Col sm={4}>
//               <input
//                 name='email'
//                 type='email'
//                 value={email}
//                 onChange={this.onChange}
//                 placeholder={'E-Mail'}
//                 required
//               />
//             </Col>
//             <Col sm={2}></Col>
//           </Row>
//           <Row>
//             <Col sm={2}></Col>
//             <Col sm={8}>
//               <textarea
//                 name='message'
//                 type='text'
//                 value={message}
//                 onChange={this.onChange}
//                 placeholder={'Nachricht...'}
//                 required
//               ></textarea>
//             </Col>
//             <Col sm={2}></Col>
//           </Row>
//           <Row>
//             <Col sm={2} />
//             <Col sm={8}>
//               <p className=''>{sendingStatus}</p>
//             </Col>
//             <Col sm={2} />
//           </Row>
//           <Row>
//             <Col sm={5} xs={3}></Col>
//             <Col sm={2} xs={6}>
//               <button type='submit'>absenden</button>
//             </Col>
//             <Col sm={5} xs={3}></Col>
//           </Row>
//         </form>
//       </Container>
//     );
//   }
// }

// export default ContactForm;
