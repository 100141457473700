import React, { useEffect } from 'react';
import NavBar from '../basic/Navbar';
import AboutSection from '../sections/AboutSection';
import ListOfWorkSection from '../sections/ListOfWorkSection';
import Footer from '../basic/Footer';

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className='pageContainer'>
      <NavBar sticky={true} />
      <AboutSection />
      <ListOfWorkSection />
      <Footer />
    </div>
  );
};

export default AboutPage;
