import React from 'react';
import sozialforschungImg from '../../assets/sozialforschungImg.jpg';
import beratungImg from '../../assets/beratungImg.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const OfferSection = props => {
  return (
    <Container id='offerSection' ref={props.refProp}>
      <Row>
        <Col md={1}></Col>
        <Col md={4}>
          <div className='offerBox'>
            <img className='offerImg' src={sozialforschungImg} alt=''></img>
            <Link className='cleanLink' to='/sozialforschung'>
              <h1 className='offerLink'>Sozialforschung</h1>
            </Link>
          </div>
        </Col>
        <Col md={2}>
          <h1 className='verticalText'>Angebote</h1>
        </Col>
        <Col md={4}>
          <div className='offerBox'>
            <img className='offerImg' src={beratungImg} alt=''></img>
            <Link className='cleanLink' to='/beratung'>
              <h1 className='offerLink'>Beratung</h1>
            </Link>
          </div>
        </Col>
        <Col md={1}></Col>
      </Row>
    </Container>
  );
};

export default OfferSection;
