import gql from "graphql-tag";

const QUERY_PUB_GUTACHTEN = gql`
  query ($postTitle: String!) {
    publikations(
      filters: {
        art: { eq: "PublikationGutachten" }
        post: { titel: { eq: $postTitle } }
      }
      sort: "datum:DESC"
      pagination: { limit: 1000 }
    ) {
      data {
        attributes {
          text
          link
        }
      }
    }
  }
`;

export default QUERY_PUB_GUTACHTEN;
