import React from "react";
import { Row, Col } from "react-bootstrap";

const SingleImg = (props) => {
  const DB_URL = process.env.REACT_APP_PUBLIC_API_URL;
  const image = props.image;

  const getImgURL = () => {
    if (image.formats.hasOwnProperty("large")) {
      return `${DB_URL}${image.formats.large.url}`;
    } else if (image.formats.hasOwnProperty("medium")) {
      return `${DB_URL}${image.formats.medium.url}`;
    } else if (image.formats.hasOwnProperty("small")) {
      return `${DB_URL}${image.formats.small.url}`;
    }
  };

  return (
    <Row id="singleImg">
      <Col sm={3}></Col>
      <Col sm={6}>
        {image.hasOwnProperty("caption") ? (
          <a
            href={image.caption !== image.name ? image.caption : null}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="imgWithLink" src={getImgURL()} alt="" />
          </a>
        ) : (
          <img src={getImgURL()} alt="" />
        )}
      </Col>
      <Col sm={3}></Col>
    </Row>
  );
};

export default SingleImg;
