import React from "react";
import { Row, Col } from "react-bootstrap";

const ImgGallery = (props) => {
  const images = props.images;
  const DB_URL = process.env.REACT_APP_PUBLIC_API_URL;

  const num_images = props.images.length;
  let num_rows = (num_images - (num_images % 4)) / 4;
  if (num_images % 4 > 0) {
    num_rows += 1;
  }

  const getCurrentImgURL = (index) => {
    if (images[index].formats.hasOwnProperty("large")) {
      return `${DB_URL}${images[index].formats.large.url}`;
    } else if (images[index].formats.hasOwnProperty("medium")) {
      return `${DB_URL}${images[index].formats.medium.url}`;
    } else if (images[index].formats.hasOwnProperty("small")) {
      return `${DB_URL}${images[index].formats.small.url}`;
    }
  };

  const createGallery = () => {
    let gallery = [];

    for (let i = 0; i < num_rows; i++) {
      let children = [];
      children.push(<Col key={`${i}.left`} sm={3}></Col>);
      for (let j = 0; j < 4; j++) {
        const imageIndex = i * 4 + j;
        if (imageIndex < num_images) {
          children.push(
            <Col className="imgContainer" key={imageIndex}>
              {images[imageIndex].caption !== "" &&
              images[imageIndex].caption !== images[imageIndex].name ? (
                <a
                  href={
                    images[imageIndex].caption !== images[imageIndex].name
                      ? images[imageIndex].caption
                      : null
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="imgWithLink"
                    src={getCurrentImgURL(imageIndex)}
                    alt=""
                  />
                </a>
              ) : (
                <img src={getCurrentImgURL(imageIndex)} alt="" />
              )}
            </Col>
          );
        } else {
          // Push empty imgContainer to prevent images from beeing enlarged
          children.push(
            <Col className="imgContainer" key={imageIndex} />
          )
        }
      }
      children.push(<Col key={`${i}.right`} sm={3}></Col>);
      gallery.push(<Row key={`${i}.row`}>{children}</Row>);
    }
    return gallery;
  };

  return <div id="imgGallery">{createGallery()}</div>;
};

export default ImgGallery;
