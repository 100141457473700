import React, { useRef, useState, useEffect } from "react";
import { v4tov3 } from "../../utils/v4tov3";
import { useQuery } from "@apollo/client";
import plusIcon from "../../assets/plusIcon.svg";
import minusIcon from "../../assets/minusIcon.svg";
import WorkItem from "../basic/WorkItem";

const ListOfWork = (props) => {
  const { query } = props;

  const plusIconRef = useRef();
  const minusIconRef = useRef();
  const workItemContRef = useRef();

  const { data: v4publikationsData } = useQuery(query);
  const [publikationsData, setPublikationsData] = useState();

  useEffect(() => {
    if (v4publikationsData) {
      setPublikationsData(
        v4tov3({ v4res: v4publikationsData, key: "publikations" })
      );
    }
  }, [v4publikationsData]);

  const toggleExtented = (e) => {
    const targetClassList = e.target.classList;
    if (
      targetClassList.contains("plusIcon") &&
      !targetClassList.contains("hidden")
    ) {
      targetClassList.add("hidden");
      minusIconRef.current.classList.remove("hidden");
      workItemContRef.current.classList.remove("hidden");
    } else if (
      targetClassList.contains("minusIcon") &&
      !targetClassList.contains("hidden")
    ) {
      targetClassList.add("hidden");
      plusIconRef.current.classList.remove("hidden");
      workItemContRef.current.classList.add("hidden");
    }
  };

  return (
    <div id="listOfWork" onClick={toggleExtented}>
      <img
        className="extIcon plusIcon"
        src={plusIcon}
        alt={"plus icon"}
        onClick={toggleExtented}
        ref={plusIconRef}
      />
      <img
        className="extIcon minusIcon hidden"
        src={minusIcon}
        alt={"minus icon"}
        onClick={toggleExtented}
        ref={minusIconRef}
      />
      <h1>{props.title}</h1>

      {publikationsData ? (
        <div className="hidden" ref={workItemContRef}>
          {publikationsData.publikations.map((publikation, i) => {
            return (
              <WorkItem
                key={i}
                text={publikation.text}
                link={publikation.link}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ListOfWork;
