import React from 'react';
import NavBar from '../basic/Navbar';
import Posts from '../sections/Posts';
import Footer from '../basic/Footer';

import QUERY_BERATUNG from '../../queries/query_beratung';

const BeratungPage = () => {
  window.scrollTo(0, 0);
  return (
    <div className='pageContainer'>
      <NavBar sticky={true}></NavBar>
      <Posts
        category={'Beratung'}
        query={QUERY_BERATUNG}
        offerPath={'/beratung'}
      ></Posts>
      <Footer></Footer>
    </div>
  );
};

export default BeratungPage;
