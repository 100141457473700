import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const OfferHeader = props => {
  return (
    <Container id='offerHeader'>
      <Row>
        <Col sm={2}></Col>
        <Col sm={8}>
          <h1>{props.title}</h1>
          <div className='bgBar'></div>
        </Col>
        <Col sm={2}></Col>
      </Row>
    </Container>
  );
};

export default OfferHeader;
