import React, { useEffect } from "react";
import lakemannLogo from "../../assets/lakemannLogo.png";
import hamburgerIcon from "../../assets/hamburger.svg";
import { Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import PropTypes from "prop-types";

const NavBar = (props) => {
  const navbarRef = React.createRef();
  const navExtRef = React.createRef();

  useEffect(() => {
    const navBar = navbarRef.current;
    const navBarOffTop = navBar.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > navBarOffTop || props.sticky) {
        navBar.classList.add("sticky");
      } else {
        navBar.classList.remove("sticky");
      }
    });

    window.addEventListener("resize", () => {
      if (navExtRef.current != null) {
        const extNavClassList = navExtRef.current.classList;
        if (!extNavClassList.contains("hidden")) {
          toggleExt();
        }
      }
    });

    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  });

  const toggleExt = () => {
    const extNavClassList = navExtRef.current.classList;
    if (extNavClassList.contains("hidden")) {
      extNavClassList.remove("hidden");
    } else {
      extNavClassList.add("hidden");
    }
  };

  return (
    <div id="navbar" ref={navbarRef}>
      <Container>
        <Row className="desktopNav">
          <Col>
            <Link className="navLink" to="/current">
              Aktuelles
            </Link>
            <Link className="navLink" to="/contact">
              Kontakt
            </Link>
            <Link className="navLink" to="/about">
              Über mich
            </Link>
            <Link className="navLink" to="/offer">
              Angebote
            </Link>
          </Col>
          <Col md={2} lg={1}>
            <Link to="/">
              <div className="logoCon">
                <img
                  className="lakemannLogo"
                  src={lakemannLogo}
                  alt="Logo der Professor Lakemann GmbH"
                ></img>
              </div>
            </Link>
          </Col>
        </Row>
        <Row className="mobileNav">
          <Col>
            <img
              className="navHamburgerIcon"
              src={hamburgerIcon}
              alt="hamburger menue icon"
              onClick={toggleExt}
            />
          </Col>
          <Col></Col>
          <Col sm={2} xs={3}>
            <Link to="/">
              <div className="logoCon">
                <img
                  className="lakemannLogo"
                  src={lakemannLogo}
                  alt="Logo der Professor Lakemann GmbH"
                ></img>
              </div>
            </Link>
          </Col>
        </Row>
      </Container>
      <Container className="navExtContainer hidden" ref={navExtRef}>
        <Row>
          <Col>
            <Link className="navLinkExt" to="/offer">
              Angebote
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link className="navLinkExt" to="/about">
              Über mich
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link className="navLinkExt" to="/contact">
              Kontakt
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link className="navLinkExt" to="/current">
              Aktuelles
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

NavBar.propTypes = {
  sticky: PropTypes.bool.isRequired,
};

export default NavBar;
