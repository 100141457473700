import React from 'react';
import portraitImg from '../../assets/portraitImg.jpg';
import { Container, Row, Col } from 'react-bootstrap';

const AboutSection = props => {
  return (
    <Container id='aboutSection' ref={props.refProp}>
      <h1 className='aboutHeader'>Prof. Dr. Ulrich Lakemann</h1>
      <Row>
        <Col sm={1}></Col>
        <Col sm={4}>
          <img
            className='portraitImg'
            src={portraitImg}
            alt='Bild von Ulrich Lakemann'
          ></img>
        </Col>
        <Col sm={1}></Col>
        <Col sm={5}>
          <div className='resume'>
            <h1 className='dateSign'>seit 2020</h1>
            <p className='periodDescription'>
              Geschäftsführer und Inhaber der Professor Lakemann GmbH
            </p>
            <h1 className='dateSign'>1994 – 2020</h1>
            <p className='periodDescription'>
              Professor für Sozialwissenschaften an der Ernst-Abbe-Hochschule
              Jena
            </p>
            <h1 className='dateSign'>1991 – 1994</h1>
            <p className='periodDescription'>
              Projektleiter am ASIF-Institut und der Universität Bielefeld
            </p>
            <h1 className='dateSign'>1990</h1>
            <p className='periodDescription'>
              Promotion zum Dr. rer. soc. an der Universität Bielefeld, Fakultät
              für Soziologie
            </p>
            <h1 className='dateSign'>1988 – 1991</h1>
            <p className='periodDescription'>
              wissenschaftlicher Angestellter an der Universität Hannover
            </p>
            <h1 className='dateSign'>1985 – 1987</h1>
            <p className='periodDescription'>
              wissenschaftlicher Angestellter am Internationalen Institut für
              Management und Verwaltung, Wissenschaftszentrum Berlin
            </p>
            <h1 className='dateSign'>1983 – 1986</h1>
            <p className='periodDescription'>
              wissenschaftlicher Mitarbeiter der Forschungsgruppe Sozialplanung
              und Sozialverwaltung
            </p>
            <h1 className='dateSign'>1976 – 1983</h1>
            <p className='periodDescription'>
              Studium der Soziologie an der Universität Bielefeld, Abschluss:
              Diplom Soziologe
            </p>
          </div>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </Container>
  );
};

export default AboutSection;
