import React from "react";
import NavBar from "../basic/Navbar";
import Posts from "../sections/Posts";
import Footer from "../basic/Footer";

import QUERY_SOZIALFORSCHUNG from "../../queries/query_sozialforschung";

const SozialforschungPage = () => {
  window.scrollTo(0, 0);
  return (
    <div className="pageContainer">
      <NavBar sticky={true}></NavBar>
      <Posts
        category={"Sozialforschung"}
        query={QUERY_SOZIALFORSCHUNG}
        offerPath={"/sozialforschung"}
      ></Posts>
      <Footer></Footer>
    </div>
  );
};

export default SozialforschungPage;
