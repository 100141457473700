import React from 'react';
import ContactForm from '../basic/ContactForm';

const ContactSection = props => {
  return (
    <div ref={props.refProp}>
      {/* <h1 className='sectionHeader'>Kontakt</h1> */}
      <ContactForm />
    </div>
  );
};

export default ContactSection;
