import React, { useEffect } from 'react';

const LandingSection = () => {
  const landingSectionRef = React.createRef();
  const landingTextRef = React.createRef();

  useEffect(() => {
    const handleTextPosition = () => {
      const sectionVertMid = landingSectionRef.current.clientHeight / 2;
      const textHeight = landingTextRef.current.clientHeight;
      const leftMargin = -landingTextRef.current.clientWidth / 2 + 'px';

      landingTextRef.current.style.marginLeft = leftMargin;
      landingTextRef.current.style.left = '50%';

      if (window.innerWidth <= 576) {
        landingTextRef.current.style.top = String(sectionVertMid / 3) + 'px';
      } else {
        landingTextRef.current.style.top =
          String(sectionVertMid - textHeight) + 'px';
      }
    };

    window.addEventListener('resize', handleTextPosition);
    handleTextPosition();

    return () => window.removeEventListener('resize', handleTextPosition);
  }, [landingTextRef, landingSectionRef]);

  return (
    <div id='landingSection' ref={landingSectionRef}>
      <div className='landingText' ref={landingTextRef}>
        <h1 className='professor'>Professor</h1>
        <h1 className='lakemann'>Lakemann</h1>
        <h1 className='gmbh'>GmbH</h1>
        <h1 className='topics'>Sozialforschung und Beratung</h1>
      </div>
    </div>
  );
};

export default LandingSection;
