/**
 * Transforms strapi v4 graphql responses into v3 responses. This means
 * resolving the data and attribute fields.
 */

export const v4tov3 = (params) => {
  const { v4res, key } = params;
  if (!v4res || !v4res[key]) return v4res;
  let v3resAttributes;

  if (Array.isArray(v4res[key].data)) {
    v3resAttributes = v4res[key].data.map((data) => {
      const tmpAttributes = { ...data.attributes };
      if (tmpAttributes) {
        const keys = Object.keys(tmpAttributes);
        keys.forEach((k) => {
          /* eslint no-underscore-dangle: ["error", { "allow": ["__typename"] }] */
          const entry = tmpAttributes[k];
          if (
            entry &&
            entry.__typename &&
            (entry.__typename.endsWith("EntityResponse") ||
              entry.__typename.endsWith("RelationResponseCollection"))
          ) {
            tmpAttributes[k] = v4tov3({ v4res: { [k]: entry }, key: k })[k];
          }
        });
        return { ...tmpAttributes, id: data.id };
      }
      return { id: data.id };
    });
  } else if (v4res[key].data && v4res[key].data.attributes) {
    v3resAttributes = { ...v4res[key].data.attributes, id: v4res[key].data.id };
  } else if (v4res[key].data && v4res[key].data.id) {
    v3resAttributes = { id: v4res[key].data.id };
  }

  const v3res = { [key]: v3resAttributes };

  return v3res;
};

export default v4tov3;
