import React from 'react';
import NavBar from '../basic/Navbar';
import { Container, Row, Col } from 'react-bootstrap';

const DatenschutzPage = () => {
  return (
    <div>
      <NavBar sticky={true} />
      <Container id='dataProtection'>
        <Row>
          <Col sm={2} />
          <Col sm={8}>
            <h1 className='sectionHeader'>Datenschutz&shy;erkl&auml;rung</h1>
            <h2>1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>{' '}
            <p>
              Die folgenden Hinweise geben einen einfachen &Uuml;berblick
              dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn
              Sie diese Website besuchen. Personenbezogene Daten sind alle
              Daten, mit denen Sie pers&ouml;nlich identifiziert werden
              k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz
              entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten
              Datenschutzerkl&auml;rung.
            </p>
            <h3>Datenerfassung auf dieser Website</h3>{' '}
            <p>
              <strong>
                Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
                Website?
              </strong>
            </p>{' '}
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem
              Impressum dieser Website entnehmen.
            </p>{' '}
            <p>
              <strong>Wie erfassen wir Ihre Daten?</strong>
            </p>{' '}
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die
              Sie uns mitteilen, wenn Sie uns kontaktieren.
            </p>{' '}
            <p>
              <strong>Wof&uuml;r nutzen wir Ihre Daten?</strong>
            </p>{' '}
            <p>
              Ihre Daten werden lediglich in der direkten Kommunikation mit
              Ihnen genutzt.
            </p>{' '}
            <p>
              <strong>
                Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
              </strong>
            </p>{' '}
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber
              Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein
              Recht, die Berichtigung oder L&ouml;schung dieser Daten zu
              verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung
              erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit
              f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das
              Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen
              Aufsichtsbeh&ouml;rde zu.
            </p>{' '}
            <p>
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen
              Sie sich jederzeit unter der im Impressum angegebenen Adresse an
              uns wenden.
            </p>
            <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
            <h3>Externes Hosting</h3>{' '}
            <p>
              Diese Website wird bei einem externen Dienstleister gehostet
              (Hoster). Die personenbezogenen Daten, die auf dieser Website
              erfasst werden, werden auf den Servern des Hosters gespeichert.
              Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
              und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
              Websitezugriffe und sonstige Daten, die &uuml;ber eine Website
              generiert werden, handeln.
            </p>{' '}
            <p>
              Der Einsatz des Hosters erfolgt zum Zwecke der
              Vertragserf&uuml;llung gegen&uuml;ber unseren potenziellen und
              bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse
              einer sicheren, schnellen und effizienten Bereitstellung unseres
              Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs.
              1 lit. f DSGVO).
            </p>{' '}
            <p>
              Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
              zur Erf&uuml;llung seiner Leistungspflichten erforderlich ist und
              unsere Weisungen in Bezug auf diese Daten befolgen.
            </p>
            <p>
              <strong>
                Abschluss eines Vertrages &uuml;ber Auftragsverarbeitung
              </strong>
            </p>{' '}
            <p>
              Um die datenschutzkonforme Verarbeitung zu gew&auml;hrleisten,
              haben wir einen Vertrag &uuml;ber Auftragsverarbeitung mit unserem
              Hoster geschlossen.
            </p>
            <h2>3. Allgemeine Hinweise und Pflicht&shy;informationen</h2>
            <h3>Datenschutz</h3>{' '}
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer
              pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre
              personenbezogenen Daten vertraulich und entsprechend der
              gesetzlichen Datenschutzvorschriften sowie dieser
              Datenschutzerkl&auml;rung.
            </p>{' '}
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
              Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche
              Daten wir erheben und wof&uuml;r wir sie nutzen. Sie
              erl&auml;utert auch, wie und zu welchem Zweck das geschieht.
            </p>{' '}
            <p>
              Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
              (z.&nbsp;B. bei der Kommunikation per E-Mail)
              Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
              der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
            </p>
            <h3>Hinweis zur verantwortlichen Stelle</h3>{' '}
            <p>
              Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf
              dieser Website ist:
            </p>{' '}
            <p>
              Professor Lakemann GmbH Sozialforschung und Beratung
              <br />
              Mittelstra&szlig;e 17
              <br />
              07745 Jena
            </p>
            <p>
              Telefon: +49 (0) 172 9795543
              <br />
              E-Mail: info@lakemann.com
            </p>
            <p>
              Verantwortliche Stelle ist die nat&uuml;rliche oder juristische
              Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke
              und Mittel der Verarbeitung von personenbezogenen Daten
              (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.
            </p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{' '}
            <p>
              Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer
              ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen
              eine bereits erteilte Einwilligung jederzeit widerrufen. Die
              Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
              Datenverarbeitung bleibt vom Widerruf unber&uuml;hrt.
            </p>
            <h3>
              Beschwerde&shy;recht bei der zust&auml;ndigen
              Aufsichts&shy;beh&ouml;rde
            </h3>{' '}
            <p>
              Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den
              Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde,
              insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen
              Aufenthalts, ihres Arbeitsplatzes oder des Orts des
              mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht besteht
              unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>{' '}
            <p>Dies ist:</p>
            <h3>
              Thüringer Landesbeauftragte für den Datenschutz und die
              Informationsfreiheit
            </h3>{' '}
            <p>
              Postfach 90 04 55
              <br />
              99107 Erfurt
            </p>{' '}
            <p>oder:</p>
            <p>
              Häßlerstraße 8
              <br />
              99096 Erfurt
            </p>{' '}
            <p>
              Telefon: 03 61/57 311 29 00
              <br />
              Telefax: 03 61/57 311 2904
            </p>{' '}
            <p>E-Mail: poststelle@datenschutz.thueringen.de</p>{' '}
            <p>Homepage: https://www.tlfdi.de</p>
            <h2>RECHTE DER NUTZER</h2>{' '}
            <h3>Wie können Sie Ihre Rechte geltend machen?</h3>{' '}
            <p>
              Bitte nutzen Sie zur Geltendmachung Ihrer Rechte die Angaben im
              Impressum. Bitte stellen Sie dabei sicher, dass uns eine
              eindeutige Identifizierung Ihrer Person möglich ist.
            </p>{' '}
            <p>
              Bitte beachten Sie, dass Ihre Daten zunächst nur gesperrt werden,
              sofern der Löschung Aufbewahrungsfristen entgegenstehen.
            </p>{' '}
            <h3>Ihre Rechte auf Auskunft und Berichtigung</h3>{' '}
            <p>
              Sie können verlangen, dass wir Ihnen bestätigen, ob wir Sie
              betreffende personenbezogene Daten verarbeiten und Sie haben ein
              Recht auf Auskunft im Hinblick auf Ihre von uns verarbeiteten
              Daten. Sollten Ihre Daten unrichtig oder unvollständig sein,
              können Sie verlangen, dass Ihre Daten berichtigt bzw.
              vervollständigt werden. Wenn wir Ihre Daten an Dritte
              weitergegeben haben informieren wir diese über die Berichtigung,
              soweit dies gesetzlich vorgeschrieben ist.
            </p>{' '}
            <h3>Ihr Recht auf Löschung</h3>{' '}
            <p>
              Sie können, wenn die gesetzlichen Voraussetzungen vorliegen, von
              uns unverzügliche Löschung Ihrer personenbezogenen Daten
              verlangen. Dies ist insbesondere der Fall wenn
            </p>{' '}
            <ul>
              {' '}
              <li>
                Ihre personenbezogenen Daten für die Zwecke, für die sie erhoben
                wurden, nicht länger benötigt werden;
              </li>{' '}
              <li>
                die Rechtsgrundlage für die Verarbeitung ausschließlich Ihre
                Einwilligung war und Sie diese widerrufen haben;
              </li>{' '}
              <li>
                Sie der Verarbeitung zu werblichen Zwecken widersprochen haben
                („Werbewiderspruch”);
              </li>{' '}
              <li>
                Sie einer Verarbeitung auf der Basis der Rechtsgrundlage
                Interessenabwägung aus persönlichen Gründen widersprochen haben
                und wir nicht nachweisen können, dass es vorrangige berechtigte
                Gründe für eine Verarbeitung gibt;
              </li>{' '}
              <li>
                Ihre personenbezogenen Daten unrechtmäßig verarbeitet wurden;
                oder
              </li>{' '}
              <li>
                Ihre personenbezogenen Daten gelöscht werden müssen, um
                gesetzlichen Anforderungen zu entsprechen.
              </li>{' '}
            </ul>{' '}
            <p>
              Wenn wir Ihre Daten an Dritte weitergegeben haben informieren wir
              diese über die Löschung, soweit dies gesetzlich vorgeschrieben
              ist.
            </p>{' '}
            <p>
              Bitte beachten Sie, dass Ihr Löschungsrecht Einschränkungen
              unterliegt. Zum Beispiel müssen bzw. dürfen wir keine Daten
              löschen, die wir aufgrund gesetzlicher Aufbewahrungsfristen noch
              weiter vorhalten müssen. Auch Daten, die wir zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen benötigen sind von
              Ihrem Löschungsrecht ausgenommen.
            </p>
            <h3>Ihr Recht auf Einschränkung der Verarbeitung</h3>{' '}
            <p>
              Sie können, wenn die gesetzlichen Voraussetzungen vorliegen, von
              uns eine Einschränkung der Verarbeitung verlangen. Dies ist
              insbesondere der Fall wenn
            </p>{' '}
            <ul>
              {' '}
              <li>
                die Richtigkeit Ihrer personenbezogenen Daten von Ihnen
                bestritten wird, und dann solange bis wir die Möglichkeit
                hatten, die Richtigkeit zu überprüfen;
              </li>{' '}
              <li>
                die Verarbeitung nicht rechtmäßig erfolgt und Sie statt der
                Löschung (siehe hierzu den vorigen Abschnitt) eine Einschränkung
                der Nutzung verlangen;
              </li>{' '}
              <li>
                wir Ihre Daten nicht mehr für die Zwecke der Verarbeitung
                benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder
                Verteidigung Ihrer Rechtsansprüche brauchen;
              </li>{' '}
              <li>
                Sie Widerspruch aus persönlichen Gründen erhoben haben, und dann
                solange bis feststeht, ob Ihre Interessen überwiegen.
              </li>{' '}
            </ul>{' '}
            <p>
              Wenn ein Recht auf Einschränkung der Verarbeitung besteht
              markieren wir die betroffenen Daten um auf diese Weise
              sicherzustellen, dass diese nur noch in den engen Grenzen
              verarbeitet werden, die für solche eingeschränkten Daten gelten
              (nämlich insbesondere zur Verteidigung von Rechtsansprüchen oder
              mit Ihrer Einwilligung).
            </p>
            <h3>Ihr Recht auf Datenübertragbarkeit</h3>{' '}
            <p>
              Sie haben das Recht, personenbezogene Daten, die Sie uns zur
              Vertragserfüllung oder auf Basis einer Einwilligung gegeben haben,
              in einem übertragbaren Format zu erhalten. Sie können in diesem
              Fall auch verlangen, dass wir diese Daten direkt einem Dritten
              übermitteln, soweit dies technisch machbar ist.
            </p>
            <h3>Ihr Recht auf Widerruf der Einwilligung</h3>{' '}
            <p>
              Sofern Sie uns eine Einwilligung in die Verarbeitung Ihrer Daten
              erteilt haben, können Sie diese jederzeit mit Wirkung für die
              Zukunft widerrufen. Die Rechtmäßigkeit der Verarbeitung Ihrer
              Daten bis zum Widerruf bleibt hiervon unberührt.
            </p>
            <h3>SSL- bzw. TLS-Verschl&uuml;sselung</h3>{' '}
            <p>
              Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
              &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel
              Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber
              senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine
              verschl&uuml;sselte Verbindung erkennen Sie daran, dass die
              Adresszeile des Browsers von &bdquo;http://&ldquo; auf
              &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer
              Browserzeile.
            </p>{' '}
            <p>
              Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
              k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von
              Dritten mitgelesen werden.
            </p>
            <h3>Weitergabe von Daten an Dritte</h3>{' '}
            <p>
              Ihre personenbezogenen Daten werden von uns grundsätzlich nur dann
              an Dritte gegeben, soweit dies zur Vertragserfüllung erforderlich
              ist, wir oder der Dritte ein berechtigtes Interesse an der
              Weitergabe haben oder Ihre Einwilligung hierfür vorliegt. Sofern
              Daten an Dritte auf Basis eines berechtigten Interesses
              übermittelt werden, wird dies in diesen Datenschutzbestimmungen
              erläutert. Bei einer Übermittlung auf Basis einer Einwilligung
              kann die Erläuterung auch bei Einholung der Einwilligung erteilt
              werden.
            </p>{' '}
            <p>
              Darüber hinaus können Daten an Dritte übermittelt werden, soweit
              wir aufgrund gesetzlicher Bestimmungen oder durch vollstreckbare
              behördliche oder gerichtliche Anordnung hierzu verpflichtet sein
              sollten.
            </p>
            <h3>Dienstleister</h3>{' '}
            <p>
              Wir behalten uns vor, bei der Erhebung bzw. Verarbeitung von Daten
              Dienstleister einzusetzen. Dienstleister erhalten von uns nur die
              personenbezogenen Daten, die sie für ihre konkrete Tätigkeit
              benötigen. So kann z. B. Ihre E-Mail-Adresse an einen
              Dienstleister weitergeben werden, damit dieser Ihnen einen
              bestellten Newsletter ausliefern kann. Dienstleister können auch
              damit beauftragt werden, Serverkapazitäten zur Verfügung zu
              stellen. Dienstleister werden in der Regel als sogenannte
              Auftragsverarbeiter eingebunden, die personenbezogene Daten der
              Nutzer dieses Online-Angebots nur nach unseren Weisungen
              verarbeiten dürfen.
            </p>
            <h3>Weitergabe von Daten in Nicht-EWR-Länder</h3>{' '}
            <p>
              Wir geben personenbezogene Daten unter Umständen auch an Dritte
              bzw. Auftragsverarbeiter, die ihren Sitz in Nicht-EWR-Ländern
              haben. In diesem Fall stellen wir vor der Weitergabe sicher, dass
              beim Empfänger entweder ein angemessenes Datenschutzniveau besteht
              (z. B. aufgrund einer Angemessenheitsentscheidung der EU
              Kommission für das jeweilige Land, gemäß Art. 45 DSGVO, durch eine
              Selbst-Zertifizierung des Empfängers für das EU-US-Privacy Shield
              in Verbindung mit dem entsprechenden Angemessenheitsbeschluss der
              Kommission gemäß Art. 45 DSGVO oder die Vereinbarung sogenannter
              EU Standardvertragsklauseln der Europäischen Kommission mit dem
              Empfänger gemäß Art. 46 DSGVO) bzw. eine ausdrückliche
              Einwilligung unserer Nutzer vorliegt.
            </p>
            <h3>Dauer der Speicherung und Aufbewahrungsfristen</h3>{' '}
            <p>
              Wir speichern Ihre Daten solange, wie dies zur Erbringung unseres
              Online-Angebots und der damit verbundenen Services erforderlich
              ist bzw. wir ein berechtigtes Interesse an der weiteren
              Speicherung haben. In allen anderen Fällen löschen wir Ihre
              personenbezogenen Daten mit Ausnahme solcher Daten, die wir zur
              Erfüllung gesetzlicher (z. B. steuer- oder handelsrechtlicher)
              Aufbewahrungsfristen weiter vorhalten müssen (z. B. Rechnungen).
            </p>{' '}
            <p>
              Daten, die einer Aufbewahrungsfrist unterliegen, sperren wir bis
              zum Ablauf der Frist.
            </p>
            <h3>Auskunft, L&ouml;schung und Berichtigung</h3>{' '}
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein
              Recht auf Berichtigung oder L&ouml;schung dieser Daten. Hierzu
              sowie zu weiteren Fragen zum Thema personenbezogene Daten
              k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen
              Adresse an uns wenden.
            </p>
            <h3>Recht auf Einschr&auml;nkung der Verarbeitung</h3>{' '}
            <p>
              Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich
              jederzeit unter der im Impressum angegebenen Adresse an uns
              wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht
              in folgenden F&auml;llen:
            </p>{' '}
            <ul>
              {' '}
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der
                Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer
                der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
                Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{' '}
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten
                unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt
                der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung
                verlangen.
              </li>{' '}
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen,
                Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder
                Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben
                Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{' '}
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abw&auml;gung zwischen Ihren und unseren
                Interessen vorgenommen werden. Solange noch nicht feststeht,
                wessen Interessen &uuml;berwiegen, haben Sie das Recht, die
                Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen
                Daten zu verlangen.
              </li>{' '}
            </ul>{' '}
            <p>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von
              ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung
              oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von
              Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen
              nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden
              eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen
              Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <h2>4. Datenerfassung auf dieser Website</h2>
            <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{' '}
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{' '}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung
              eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung
              vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen
              &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
              berechtigten Interesse an der effektiven Bearbeitung der an uns
              gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
              wurde.
            </p>{' '}
            <p>
              Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
              verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r
              die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach
              abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
              gesetzliche Bestimmungen &ndash; insbesondere gesetzliche
              Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
            </p>
            <p>
              Quelle: <a href='https://www.e-recht24.de'>e-recht24.de</a>
            </p>
          </Col>
          <Col sm={2} />
        </Row>
      </Container>
    </div>
  );
};

export default DatenschutzPage;
