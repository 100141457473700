import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import { v4tov3 } from "../../utils/v4tov3";
import NavBar from "../basic/Navbar";
import WorkItem from "../basic/WorkItem";

import QUERY_POST_PUB from "../../queries/query_post_pub";
import QUERY_POST_VORTRAEGE from "../../queries/query_post_vortraege";

const Publications = (props) => {
  const { postTitle } = useParams();
  const location = useLocation();
  const { scrollPos, offerPath } = location.state;
  const history = useNavigate();

  const { data: v4publikationData } = useQuery(QUERY_POST_PUB, {
    variables: { postTitle },
  });

  const { data: v4vortraegeData } = useQuery(QUERY_POST_VORTRAEGE, {
    variables: { postTitle },
  });

  const [publikationData, setPublikationData] = useState();
  const [vortraegeData, setVortraegeData] = useState();

  useEffect(() => {
    if (v4publikationData) {
      setPublikationData(
        v4tov3({ v4res: v4publikationData, key: "publikations" })
      );
    }
  }, [v4publikationData]);

  useEffect(() => {
    if (v4vortraegeData) {
      setVortraegeData(v4tov3({ v4res: v4vortraegeData, key: "publikations" }));
    }
  }, [v4vortraegeData]);

  window.onpopstate = (e) => {
    history(offerPath, { scrollPos: scrollPos, replace: true });
  };

  useEffect(() => {
    window.scroll(0, 0);
  });

  return (
    <div id="publications" className="pageContainer">
      <NavBar sticky={true} />
      <Container>
        <h1 className="sectionHeader">{props.title}</h1>
        <Row>
          <Col sm={2} />
          <Col sm={8}>
            <h2>{postTitle}</h2>
          </Col>
          <Col sm={2} />
        </Row>
        {props.title === "Publikationen und Gutachten" && publikationData ? (
          <Row>
            <Col sm={2} />
            <Col sm={8}>
              {publikationData.publikations.map((publikation, i) => {
                return (
                  <WorkItem
                    text={publikation.text}
                    link={publikation.link}
                    key={i}
                  />
                );
              })}
            </Col>
            <Col sm={2} />
          </Row>
        ) : null}
        {props.title === "Vorträge" && vortraegeData ? (
          <Row>
            <Col sm={2} />
            <Col sm={8}>
              {vortraegeData.publikations.map((publikation, i) => {
                return (
                  <WorkItem
                    text={publikation.text}
                    link={publikation.link}
                    key={i}
                  />
                );
              })}
            </Col>
            <Col sm={2} />
          </Row>
        ) : null}
      </Container>
    </div>
  );
};

export default Publications;
