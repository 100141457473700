import React from "react";
import NavBar from "../basic/Navbar";
import CurrentSection from "../sections/CurrentSection";

const CurrentPage = () => {
  return (
    <div>
      <NavBar sticky={true} />
      <CurrentSection />
    </div>
  );
};

export default CurrentPage;
