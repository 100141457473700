import React, { useEffect, useState, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import arrowPrev from "../../assets/prevImgArrow.svg";
import arrowNext from "../../assets/nextImgArrow.svg";

const ImgSlideShow = (props) => {
  const images = props.images;

  const DB_URL = process.env.REACT_APP_PUBLIC_API_URL;

  const [currentImg, setCurrentImg] = useState(1);
  const [currentImgElement, setCurrentImgElement] = useState();
  const imgRef = useRef();

  const adaptHeight = () => {
    if (imgRef.current) {
      const width = imgRef.current.width;
      imgRef.current.height = (width / 6) * 4;
    }
  };

  useEffect(() => {
    setCurrentImgElement(
      <a
        href={images[currentImg].caption}
        target="_blank"
        rel="noopener noreferrer"
        className={
          images[currentImg].caption !== images[currentImg].name
            ? ""
            : "inactiveLink"
        }
      >
        <img
          className="slideImg"
          src={getCurrentImgURL()}
          alt=""
          ref={imgRef}
        />
      </a>
    );
    adaptHeight();
  }, [currentImg]);

  const nextImg = () => {
    const tmpCurrentImg = currentImg;
    if (currentImg < images.length - 1) {
      setCurrentImg(tmpCurrentImg + 1);
    } else {
      setCurrentImg(0);
    }
  };

  const prevImg = () => {
    const tmpCurrentImg = currentImg;
    if (currentImg > 0) {
      setCurrentImg(tmpCurrentImg - 1);
    } else {
      setCurrentImg(images.length - 1);
    }
  };

  const getCurrentImgURL = () => {
    if (images[currentImg].formats.hasOwnProperty("large")) {
      return `${DB_URL}${images[currentImg].formats.large.url}`;
    } else if (images[currentImg].formats.hasOwnProperty("medium")) {
      return `${DB_URL}${images[currentImg].formats.medium.url}`;
    } else if (images[currentImg].formats.hasOwnProperty("small")) {
      return `${DB_URL}${images[currentImg].formats.small.url}`;
    }
  };

  return (
    <div id="imgSlideShow">
      <Row>
        <Col sm={2} xs={0}></Col>
        <Col sm={1} xs={1}>
          <div className="switchImgBtn prevBtn" onClick={prevImg}>
            <img
              className="swArrow"
              src={arrowPrev}
              alt="vorheriges Bild"
            ></img>
          </div>
        </Col>
        <Col sm={6} xs={10}>
          {currentImgElement}
        </Col>
        <Col sm={1} xs={1}>
          <div className="switchImgBtn" onClick={nextImg}>
            <img className="swArrow" src={arrowNext} alt="naechstes Bild"></img>
          </div>
        </Col>
        <Col sm={2} xs={0}></Col>
      </Row>
    </div>
  );
};

export default ImgSlideShow;
