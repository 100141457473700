import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div id='footer'>
      <div className='footerLinks'>
        <Link className='footerLink' to='/impressum'>
          Impressum
        </Link>
        <Link className='footerLink' to='/datenschutz'>
          Datenschutz
        </Link>
      </div>
    </div>
  );
};

export default Footer;
