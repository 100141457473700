import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

const OfferContent = (props) => {
  return (
    <Container id="offerContent">
      <Row>
        <Col sm={3}></Col>
        <Col sm={6}>
          {props.contents.map((post, i) => {
            return (
              <Link
                to={`#${post.titel
                  .replace(/ü|Ü/g, "ue")
                  .replace(/ä|Ä/g, "ae")
                  .replace(/ö|Ö/g, "oe")
                  .replace(/ß/g, "ss")}`}
                key={i}
              >
                <h1>{post.titel}</h1>
              </Link>
            );
          })}
        </Col>
        <Col sm={3}></Col>
      </Row>
    </Container>
  );
};

export default OfferContent;
